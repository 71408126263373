<template>
    <div class="container pt-6">
        <div class="d-flex flex-column col-12 col-md-6 text-center m-auto">
            <div class="d-none d-md-block text-center pb-4">
                <img
                    src="@/assets/images/global/aven.svg"
                    class="logo"
                    width="71"
                    alt="Go Home"
                >
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Centered',
    }
</script>
